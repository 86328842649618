
// import getRooms from '@/firestore/roomsRepository'
import { defineComponent, ref, reactive, onMounted } from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { get, getWithSnapshot } from '@/firestore/roomsRepository'
// import { useRoute, useRouter } from 'vue-router'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'
import { Room, Wish } from '@/types/wishlist'

import RoomListItem from '@/components/room/RoomListItem.vue'

export default defineComponent({
  components: { RoomListItem },
  setup() {
    const db = firebase.firestore()
    const router = useRouter()
    const store = useStore()
    const rooms: Room[] = reactive([])

    // const add = () => {
    //   router.push('/rooms/add')
    // }

    const onAddRoom = () => router.push('/rooms/add')

    // const onShowRoom = (target: Room) =>
    //   router.push({ name: 'RoomShow', params: { id: target.id as string } })

    const onShowWishList = (target: Room) =>
      router.push({ name: 'WishListShow', params: { id: target.id as string } })

    const onEditRoom = () => {
      alert('edit room')
    }

    const onInviteRoom = (target: Room) => {
      router.push({ name: 'RoomInvite', params: { id: target.id as string } })
    }

    const onDeleteRoom = (target: Room) => {
      if (!confirm('削除してもよろしいですか')) {
        return
      }
      const deleteIndex = rooms.findIndex((r) => r.id === target.id)
      if (deleteIndex === -1) {
        return
      }
      console.log(deleteIndex, target)
      const targetDocRef = db.collection('rooms').doc(target.id)

      // TODO メモリ不足対応する
      targetDocRef
        .collection('wishes')
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.map((subDoc) => {
            subDoc.ref.delete()
          })
        })

      targetDocRef.delete().then(() => {
        console.log('delete!')
        // rooms.splice(deleteIndex, 1)
      })
      /*
        db.collection('rooms')
          .doc(target.id)
          .delete()
          .then(() => {
            rooms.splice(deleteIndex, 1)
          })
          */
    }

    // const readRoomOnSnapshot = () => {
    //   getWithSnapshot(rooms, store.state.user?.uid)
    // }

    onMounted(() => {
      getWithSnapshot(rooms, store.state.user)
      // readRoomOnSnapshot()
    })

    return {
      rooms,
      onAddRoom,
      onShowWishList,
      onEditRoom,
      onDeleteRoom,
      onInviteRoom,
      // toggleSubMenu,
      // subMenuActive,
    }
  },
})


import { defineComponent, PropType, ref } from 'vue'
import { Room } from '@/types/wishlist'
// import RoomInviteModal from '@/components/room/RoomInviteModal.vue'

export default defineComponent({
  name: 'RoomListItem',
  // components: { RoomInviteModal },
  props: {
    room: {
      type: Object as PropType<Room>,
      required: true,
    },
  },
  emits: ['show', 'edit', 'delete', 'invite'],
  setup(props, context) {
    // const room = props.room
    const menuActive = ref(false)

    // const room: Room = ref(props.room)
    const openMenu = () => {
      menuActive.value = true
      console.log('open')
    }
    const closeMenu = () => (menuActive.value = false)
    const toggleMenu = () => (menuActive.value = !menuActive.value)

    const onShow = () => {
      context.emit('show')
      closeMenu()
      console.log('show')
    }
    const onEdit = () => {
      context.emit('edit')
      console.log('edit')
      closeMenu()
    }
    const onDelete = () => {
      context.emit('delete')
      console.log('delete')
      closeMenu()
    }

    const onInvite = () => {
      context.emit('invite')
      closeMenu()
    }

    return {
      // item: props.room,
      onEdit,
      onShow,
      onDelete,
      onInvite,
      // openMenu,
      toggleMenu,
      closeMenu,
      menuActive,
    }
  },
})

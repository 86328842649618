<template>
  <div class="card" @click="onShow">
    <div class="card-header">
      <p class="card-header-title">{{ room.name }}</p>
      <div class="dropdown is-right" :class="menuActive ? 'is-active' : null">
        <div class="dropdown-trigger">
          <button
            class="card-header-icon"
            aria-haspopup="true"
            aria-controls="dropdown-menu4"
            @click.stop="toggleMenu"
          >
            <span class="icon">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu4" role="menu">
          <div class="dropdown-content">
            <a @click.stop="onInvite" class="dropdown-item"> Invite </a>
            <a href="#!" @click.stop="onEdit" class="dropdown-item"> Edit </a>
            <hr class="dropdown-divider" />
            <a @click.stop="onDelete" class="dropdown-item"> Delete </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <router-link :to="{ name: 'WishListShow', params: { id: room.id } }">
          {{ room.wishes.length }} 個登録中
          <!-- <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time> -->
        </router-link>
      </div>
    </div>
    <!-- <teleport to="body">
      <room-invite-modal :show="showModal"></room-invite-modal>
    </teleport> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { Room } from '@/types/wishlist'
// import RoomInviteModal from '@/components/room/RoomInviteModal.vue'

export default defineComponent({
  name: 'RoomListItem',
  // components: { RoomInviteModal },
  props: {
    room: {
      type: Object as PropType<Room>,
      required: true,
    },
  },
  emits: ['show', 'edit', 'delete', 'invite'],
  setup(props, context) {
    // const room = props.room
    const menuActive = ref(false)

    // const room: Room = ref(props.room)
    const openMenu = () => {
      menuActive.value = true
      console.log('open')
    }
    const closeMenu = () => (menuActive.value = false)
    const toggleMenu = () => (menuActive.value = !menuActive.value)

    const onShow = () => {
      context.emit('show')
      closeMenu()
      console.log('show')
    }
    const onEdit = () => {
      context.emit('edit')
      console.log('edit')
      closeMenu()
    }
    const onDelete = () => {
      context.emit('delete')
      console.log('delete')
      closeMenu()
    }

    const onInvite = () => {
      context.emit('invite')
      closeMenu()
    }

    return {
      // item: props.room,
      onEdit,
      onShow,
      onDelete,
      onInvite,
      // openMenu,
      toggleMenu,
      closeMenu,
      menuActive,
    }
  },
})
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
}
</style>
